@import "../../common/scss/colors";
.lesson-general {
  .btn {
    border-width: 2px;
    border-color: white;
  }
}

.react-audio-player {
  width: 100%;
}

.ts-schedule-thumbnail {
  position: relative;
  min-height: 150px;
  aspect-ratio: 16 / 9;
  border: 2px solid $primary-02;
  display: grid;
  place-items: center;
  isolation: isolate;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: -1;
  }
}
.ts-lesson-general__thumbnail {
  max-width: 100%;
  max-height: 800px;
}
